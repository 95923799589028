<template>
  <div id="p1s4c2" class="h-full">

    <div v-if="paymentLinks.length === 0" class="text-center">
      <span class="text-lg font-bold">No transactions found for the selected filters</span>
    </div>
    
    <!-- Payment details table -->
    <div v-else class="overflow-hidden border border-gray-400 rounded-xl m-3 h-[50vh] overflow-scroll hide-scrollbar">
      <!-- Column Filters -->
      <div class="absolute dropdown right-0 mt-1 mr-10 w-10">
        <button @mouseover="DV_showGearTooltip = true" @mouseleave="DV_showGearTooltip = false" @click="toggleDropdown" class="tooltip text-xl" :style="{backgroundColor: DV_filterColumnDropdownVisible ? '#23395d' : '#007bff'}" style="color: white;">
          &#9881;
        </button>
        <div v-if="DV_filterColumnDropdownVisible" class="dropdown-content">
          <label v-if="!DV_ignoreFilters.includes(column.name)" v-for="column in columnFilter">
            <input type="checkbox" v-model="column.visible" class="mr-2" @change="columnFilterChanged" /> {{ column.name }}
          </label>
        </div>
        <span v-show="DV_showGearTooltip === true && DV_filterColumnDropdownVisible === false">
        </span>
      </div>
    
      <table class="w-full rounded-xl text-center">
        <thead class="border-b">
          <tr class="text-center bg-gray-100">
            <th v-show="columnFilter.order_number.visible" scope="col" class="font-bold text-center">Order Number</th>
            <th v-show="columnFilter.payment_link_created.visible" scope="col" class="font-bold text-center">Payment Link Created</th>
            <th v-show="columnFilter.payment_timestamp.visible" scope="col" class="font-bold text-center">Payment Timestamp</th>
            <th v-show="columnFilter.type.visible" scope="col" class="font-bold text-center">Type</th>
            <th v-show="columnFilter.billing_address.visible" scope="col" class="font-bold text-center">Billing Address</th>
            <th v-show="columnFilter.shipping_address.visible" scope="col" class="font-bold text-center">Shipping Address</th>
            <th v-show="columnFilter.cash_amount.visible" scope="col" class="font-bold text-center">Cash Amount</th>
            <th v-show="columnFilter.card_amount.visible" scope="col" class="font-bold text-center">Card Amount</th>
            <th v-show="columnFilter.status.visible" scope="col" class="font-bold text-center">Status</th>
            <th v-show="columnFilter.brand.visible" scope="col" class="font-bold text-center">Brand</th>
            <th v-show="columnFilter.last4.visible" scope="col" class="font-bold text-center">Last 4</th>
            <th v-show="columnFilter.cardholder_name.visible" scope="col" class="font-bold text-center">Cardholder Name</th>
            <th v-show="columnFilter.description.visible" scope="col" class="font-bold text-center">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="paymentLink in C_filterPaymentLinks" class="border-b bg-white">
            <td v-if="paymentLink.payment_status === 'Approved' || paymentLink.payment_status === 'Partial Refund' || paymentLink.payment_status === 'Refunded'" style="position: relative;">
              <span
                v-if="!paymentLink.downloading_receipt"
                class="cursor-pointer"
                style="color: blue;"
                :style="{ textDecoration: paymentLink.mouse_hover ? 'underline': 'none'}"
                @click="downloadPdfReceipt(paymentLink)"
                @mouseover="paymentLink.mouse_hover = true"
                @mouseleave="paymentLink.mouse_hover = false"
              >
                {{ paymentLink.order_number }}
              </span>
              <span 
                v-if="paymentLink.downloading_receipt && DV_downloadingReceipts"
                :style="{cursor: DV_cursorStyle}"
              >
                Downloading Receipt...
              </span>
  
              <button
                v-show="!paymentLink.downloading_receipt"
                v-if="paymentLink.transaction_id != '' && paymentLink.payment_status != 'Refunded'" 
                class="btn btn-red cursor-pointer chat-action-button mr-1 mt-1 mb-1" 
                @click="confirmRefund(paymentLink)" 
                style="position: absolute; right: 0; top:0; bottom:0;"
              >&#8629;</button>
  
              <PopupModal 
                ref="confirmRefundModal"
                v-show="DV_refundModalOpened == true" 
                :P_showClose="true" 
                @close-modal="closeConfirmRefundModal(DV_selectedPayLink)"
              >
                <!-- Heading -->
                <div slot="title">
                  <h3 class="font-bold">Refund Payment</h3>
                </div>

                <template slot="body">
                  <div class="flex flex-row items-center justify-center">
                    <span class="mr-2">Refund</span>
                    <input
                      type="text"
                      v-model="DV_selectedPayLink.refund_amount_not_saved"
                      class="ml-2"
                      @input="filterNumericInput($event, DV_selectedPayLink)"
                    />
                  </div>
                  <div class="flex items-center justify-center">
                    <span v-if="DV_selectedPayLink.refunded_amount">Maximum is ${{ Math.abs(DV_selectedPayLink.refunded_amount - DV_selectedPayLink.amount).toFixed(2) }}</span>
                    <span v-if="!DV_selectedPayLink.refunded_amount">Maximum is ${{ DV_selectedPayLink.amount }}</span>
                  </div>
                </template>

                <template slot="footer"> 
                  <div class="flex flex-row items-center justify-center">
                    <button class="btn btn-lime mr-4" @click="processRefund(DV_selectedPayLink)">Confirm</button>
                    <button class="btn btn-red ml-4" @click="closeConfirmRefundModal(DV_selectedPayLink)">Cancel</button>
                  </div>
                </template>
              </PopupModal>
            </td>

            <td v-if="paymentLink.payment_status != 'Approved' && paymentLink.payment_status != 'Partial Refund' && paymentLink.payment_status != 'Refunded'">{{ paymentLink.order_number }}</td>
            
            <td v-show="columnFilter.payment_link_created.visible">{{ paymentLink.date_time.format('MM/DD/YYYY h:mma') }} {{ DV_timeZoneName }}</td>
            <td v-show="columnFilter.payment_timestamp.visible">{{ paymentLink.payment_timestamp }}</td>
            <td v-show="columnFilter.type.visible">{{ paymentLink.transaction_type }}</td>
            
            <td v-show="columnFilter.billing_address.visible">{{ paymentLink.billing_address }}</td>
            <td v-show="columnFilter.shipping_address.visible">{{ paymentLink.shipping_address }}</td>
            
            <td v-show="columnFilter.cash_amount.visible" v-if="paymentLink.payment_status == 'Refunded'">(${{ formatAmount(paymentLink.cash_amount) }})</td>
            <td v-show="columnFilter.cash_amount.visible" v-if="paymentLink.payment_status != 'Refunded'">${{ formatAmount(paymentLink.cash_amount) }}</td>
            
            <td>${{ formatAmount(paymentLink.card_amount) }}</td>
            <!-- AVS declined Status-->
            <td
              v-if="paymentLink.payment_status === 'Declined' && paymentLink.avs_status_code"
            >
              <span v-if="paymentLink.show_tooltip_info === false">
                {{ paymentLink.payment_status }} - {{ paymentLink.avs_status_code }}
              </span>  
              <span
                v-if="paymentLink.show_tooltip_info === false" 
                class="tooltip" 
                @mouseover="showTooltipHandler(paymentLink, paymentLink.avs_status_description)"
              >
                ?
              </span>
              <span
                v-if="paymentLink.show_tooltip_info === true" 
                @mouseout="hideTooltipHandler(paymentLink)"
              >
                {{ DV_toolTipContent }}
              </span>
            </td>
            
            <!-- Refund Status-->
            <td
              v-else-if="paymentLink.payment_status === 'Partial Refund'"
            >
              <span 
                v-if="paymentLink.show_tooltip_info === false"
              >
                {{ paymentLink.payment_status }}
              </span>
              <span
                v-if="paymentLink.show_tooltip_info === false"
                class="tooltip"
                @mouseover="showTooltipHandler(paymentLink, paymentLink.partial_refund_description)"
              >
                ?
              </span>
              <span
                v-if="paymentLink.show_tooltip_info === true"
                @mouseout="hideTooltipHandler(paymentLink)"
              >
                {{ DV_toolTipContent }}
              </span>
            </td>
            <td v-else>{{ paymentLink.payment_status }}</td>
            
            <td v-show="columnFilter.brand.visible">{{ paymentLink.card_type }}</td>
            <td v-show="columnFilter.last4.visible">{{ paymentLink.last4_digits }}</td>
            <td v-show="columnFilter.cardholder_name.visible">{{ paymentLink.cardholder_name }}</td>
            <td v-show="columnFilter.description.visible">{{ paymentLink.description }}</td>
          </tr>
        </tbody>
      </table> 
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers";
import generalFunctions from "../../mixins/generalFunctions";
import AWN from "awesome-notifications";
import dataValidator from "../../includes/bi1Datavalidation";
import {CR,RC} from "../../includes/bi2SuccessCodes";
import requestEmailReceipt from '../../includes/server_calls/p1s5/p1s5e7';
import processRefundForAxe from '../../includes/server_calls/p1s3/p1s3e54';
import moment from 'moment-timezone';
import PopupModal from "../shared/PopupModal.vue";
import { fs36Writer } from "../../includes/firestore_writers/fs36_writer";
import draggable from 'vuedraggable';

export default {
  name: "p1s4c2PaymentLinks",

  props: [
    "paymentLinks",
    "entriesPerPageFilter", 
    "startDateFilter", 
    "endDateFilter", 
    "searchFilter", 
    "transactionTypeFilter", 
    "paymentStatusFilter", 
    "cardTypeFilter",
    "quickDateFilter",
    "columnFilter",
  ],

  mixins: [m2MiscDataFetchers, generalFunctions],

  components: {PopupModal, draggable},

  data() {
    return {
      DV_cursorStyle: 'pointer',
      DV_downloadingReceipts: false,
      DV_timeZoneName: moment.tz(moment.tz.guess()).zoneAbbr(),
      DV_showTooltipContent: false,
      DV_toolTipContent: "",
      DV_refundModalOpened: false,
      DV_processingRefund: false,
      DV_selectedPayLink: {},
      DV_filterColumnDropdownVisible: false,
      DV_showGearTooltip: false,
      DV_columnFilters: this.columnFilter,
      DV_ignoreFilters: ["Order Number", "Card Amount", "Status"],
    };
  },

  mounted() {
    // load cached column filter. causing undefined error on p1s4c3
    let cachedColumnFilter = window.localStorage.getItem("p1s4c2_column_filter") || "";

    if (cachedColumnFilter) {
      this.DV_columnFilters = JSON.parse(cachedColumnFilter);
    }
  },

  computed: {
    C_filterPaymentLinks() {
      const CI = this;
      let paymentLinks = CI.paymentLinks;

      // sort latest payment links
      paymentLinks = paymentLinks.sort((a, b) => {
        return b.date_time.valueOf() - a.date_time.valueOf();
      });

      return paymentLinks.slice(0, CI.entriesPerPageFilter);
    },       
  },

  methods: {
    toggleDropdown() {
      this.DV_filterColumnDropdownVisible = !this.DV_filterColumnDropdownVisible;
    },

    columnFilterChanged() {
      window.localStorage.setItem("p1s4c2_column_filter", JSON.stringify(this.columnFilter))
    },

    formatAmount(amount) {
      return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },

    filterNumericInput(event, paymentLink) {
      const target = event.target;
      let filteredValue = target.value.replace(/[^0-9]/g, '');
      
      if (filteredValue.length >= 3) {
        filteredValue = (Number(filteredValue) / 100).toFixed(2)  
        filteredValue = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(filteredValue);
      }

      target.value = filteredValue;
      paymentLink.refund_amount_not_saved = filteredValue;
    },

    openRefundModal() {
      this.DV_refundModalOpened = true;
    },

    confirmRefund(paymentLink) {
      const CI = this;

      // confirm refund
      let notifier = new AWN();
      
      notifier.confirm(
        // Label
        "Are you certain you want to proceed with issuing a refund for this transaction?",
        
        // On OK
        function() {
          CI.DV_selectedPayLink = paymentLink;
          CI.openRefundModal();
        },
        // On Cancel
        function() {
        },
        // Other options
        {
          labels: {
            confirm: 'Issue Refund',
            confirmOk: "Yes",
            confirmCancel: "No"
          }
        }
      );
      // </end> confirm refund
    },
    
    closeConfirmRefundModal(paymentLink) {
      paymentLink.refund_amount_not_saved = null;

      this.DV_selectedPayLink = {};
      this.DV_refundModalOpened = false;
      this.DV_processingRefund = false;
    },
    
    processRefund(paymentLink) {
      const CI = this;

      CI.DV_processingRefund = true; 
      CI.DV_refundModalOpened = false;

      if (!paymentLink.refund_amount_not_saved || paymentLink.refund_amount_not_saved == 0) { 
        CI.$awn.alert("Refund cannot be $0.00");

        paymentLink.refund_amount_not_saved = null;
        CI.DV_processingRefund = false;
        return; 
      }

      let refund_amount_not_saved = parseFloat(paymentLink.refund_amount_not_saved.replace(/,/g, ''));
 
      if (!paymentLink.refunded_amount && refund_amount_not_saved > paymentLink.cash_amount) {
        CI.$awn.alert(`Refund cannot be more than $${paymentLink.cash_amount.toFixed(2)}`)
        
        paymentLink.refund_amount_not_saved = null;
        CI.DV_processingRefund = false;
        return;
      }

      if (paymentLink.refunded_amount) {
        let postRefundAmount = Math.abs(paymentLink.refunded_amount - paymentLink.cash_amount);
        
        if (refund_amount_not_saved > postRefundAmount) {
          CI.$awn.alert(`Refund cannot be more than $${postRefundAmount.toFixed(2)}`);
          
          paymentLink.refund_amount_not_saved = null;
          CI.DV_processingRefund = false;
          return;
        }
      }

      // write refund amount to fs36
      fs36Writer("e",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        paymentLink.consumer_contact_uid,
        paymentLink.conversation_uid,
        paymentLink.payment_link_uid,
        {
          refund_amount: refund_amount_not_saved,
        }
      );

      let refundParams = {
        recycler_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
        recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        consumer_contact_uid: paymentLink.consumer_contact_uid,
        conversation_uid: paymentLink.conversation_uid,
        fs36_doc_id: paymentLink.payment_link_uid,
      }

      processRefundForAxe(refundParams).then((resp) => {
        // process post-refund
        if (resp.success === false) {
          CI.DV_processingRefund = false;
          CI.$awn.alert("Failed to Process Refund for " + paymentLink.description)
          return;
        }

        CI.DV_processingRefund = false;
        CI.$awn.success(paymentLink.description + " for " + "$" + refund_amount_not_saved.toFixed(2) + " successfully refunded.")
      }).catch((error) => {
        const errorMessage = error.message;

        CI.DV_processingRefund = false;
        CI.$awn.alert("Failed to Process Refund for " + paymentLink.description)
      });
    },

    togglePaymentLinkMenu(paymentLink) {
      this.C_filterPaymentLinks.forEach((link) => {
        if (link.payment_link_uid === paymentLink.payment_link_uid) {
          link.show_menu = !link.show_menu;
        } else {
          link.show_menu = false;
        }
      });
    },

    showTooltipHandler(paymentLink, content) {
      const CI = this;
      paymentLink.show_tooltip_info = true;

      CI.DV_toolTipContent = content;
      CI.DV_showTooltipContent = true;
    },

    hideTooltipHandler(paymentLink) {
      paymentLink.show_tooltip_info = false;
      this.DV_showTooltipContent = false;
    },

    downloadPdfReceipt(paymentLinkData) {
      const CI = this;

      paymentLinkData.show_menu = false;
      CI.DV_cursorStyle = 'wait';
      CI.DV_downloadingReceipts = true;
      paymentLinkData.downloading_receipt = true;
      
      let params = {
        consumer_uid: paymentLinkData.consumer_contact_uid,
        recycler_business_uid:
          window.G_bi2_data.IV_active_employee_info.business_uid,
        recycler_location_uid:
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        conversation_uid: paymentLinkData.conversation_uid,
        payment_link_uid: paymentLinkData.payment_link_uid,
        email: "placeholder@email.com",
        request_type: 1,
      };

      requestEmailReceipt(params)
        .then((resp) => {
          // Save the PDF with a specified name
          try {
            if (resp.content_receipt) {
              // Decode the Base64 data
              const decodedData = atob(resp.content_receipt);

              // Convert the decoded data into a Blob
              const blob = new Blob([decodedData], { type: 'application/pdf' });
              let linkSource = URL.createObjectURL(blob);
              
              let fileName = paymentLinkData.order_number + "_" + paymentLinkData.description.split(' ').join('_') + ".pdf";

              let downloadLink = document.createElement("a");
              downloadLink.classList.add("hide");
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.textContent = 'Download PDF Now'; 

              // Append the link to the document
              document.body.appendChild(downloadLink);

              // Trigger a click event to start the download
              downloadLink.click();
              downloadLink.remove();

              // Clean up by revoking the blob URL
              URL.revokeObjectURL(linkSource);
            }
          } catch (e) {
            CI.$awn.alert(
              "Failed to download the PDF. Please reload page and try again. Or contact support if the issue persist."
            );
          }
          
          paymentLinkData.downloading_receipt = false;
          
          CI.DV_cursorStyle = 'pointer';
          CI.DV_downloadingReceipts = false;
        })
        .catch((error) => {
          const errorMessage = error.message;
          
          paymentLinkData.downloading_receipt = false;
          
          CI.DV_cursorStyle = 'pointer';
          CI.DV_downloadingReceipt = false;
          
          CI.$awn.alert(
            "Failed to download the PDF. Please reload page and try again. Or contact support if the issue persist."
          );
        });
    },
  },

  watch: {}
};
</script>

<style>

/* Simple styling for dropdown */
.dropdown {
  display: inline-block;
}

.dropdown-button {
  padding: 8px;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 9999;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 2fr)); /* Adjust the minmax value as needed */
  gap: 10px;
  right: 0;
}

.dropdown-content label {
  display: flex;
  align-items: center;
}

.drag-handle {
  margin-right: 5px;
}
</style>